import 'lity';
import LazyLoad from 'vanilla-lazyload';
import moment from 'moment';
import L from 'leaflet';
import Dropzone from "dropzone";

Dropzone.autoDiscover = false;

window.moment = moment;

window.$ = window.jQuery = require('jquery');

require('./bootstrap');
require('tempusdominus-bootstrap-4');
require('slick-carousel');
require('jquery-easing');
require('./components/fontawesome');

import objectFitImages from 'object-fit-images';
import bootstrap from 'bootstrap';

try {
    window.Popper = require('popper.js').default;
} catch (e) {}

window.onload = () =>{
    new LazyLoad({
        elements_selector: '.lazy'
    });
};

$(function() {
    objectFitImages();
});